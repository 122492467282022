import type { OpenPopupEntry } from '../stores/popups'
import type { MaybeElementRef } from '@vueuse/core'
import type { MaybeRefOrGetter } from 'vue'

/**
 * @example
 * {
 * // A function to close the popup
 * closeCallback: () => void
 * // Whether the scroll should be
 * // locked when the popup is open (default: `true`)
 * lockScroll?: boolean
 *
 * // A template element ref to close
 * // the popup when clicked outside of it
 * // MUST BE SET IN ORDER TO ENABLE THIS FUNCTIONALITY
 * closeOnClickOutside?: MaybeElementRef
 *
 * // A list of template element refs
 * // to ignore when checking if the
 * // click was outside the popup
 * ignoreElements?: MaybeElementRef[]
 *
 * // Whether the scroll should be
 * // unlocked when the popup is closed
 * // and there are no other scroll-locking popups
 * // (default: `true`)
 * //
 * // if the option is disabled (maybe in case
 * // you use animations and need to ensure
 * // the scroll is unlocked only after the
 * // animation is finished, the function
 * // to try to unlock the scroll needs
 * // to be called manually)
 * autoUnlockScroll?: boolean
 * }
 */
type PopupOptions = OpenPopupEntry & {
    /**
     * A template element ref, which when clicked outside of it,
     * the popup will be closed
     *
     * MUST BE SET IN ORDER TO ENABLE THIS FUNCTIONALITY
     */
    closeOnClickOutside?: MaybeElementRef
    /**
     * A list of template element refs
     * to ignore when checking if the
     * click was outside the popup
     */
    ignoreElements?: MaybeRefOrGetter<MaybeElementRef[]>
    /**
     * Whether the scroll should be
     * unlocked when the popup is closed
     * and there are no other scroll-locking popups
     *
     * if the option is disabled (maybe in case
     * you use animations and need to ensure
     * the scroll is unlocked only after the
     * animation is finished, the function
     * to try to unlock the scroll needs
     * to be called manually)
     * @default true
     */
    autoUnlockScroll?: boolean
    /**
     * When to automatically close the popup
     * (e.g. when the route changes)
     * @default undefined
     */
    autoCloseOn?: 'route-change'
}

/**
 * A composable meant to manage the opening and closing of popups.
 * This ensures that the scroll is locked and unlocked when a popup requires it, as well as adds the ability
 * to close the popup with the ESC key, outside click etc.
 *
 * @param isOpen a ref that indicates whether the popup is open or not (should be the same ref as the one used in the `v-model` binding)
 * @param popup a function reference to close the popup or an object with the close callback and other options
 * @param callbacks optional callbacks to be called (in the watcher) when the popup is opened or closed
 */
export default function useManagePopupOpening(isOpen: Ref<boolean | undefined>, popup: PopupOptions | (() => void), callbacks: OpenCloseCallbacks | null = null) {

    const { saveOpenPopup, cleanupOpenPopup, tryToUnlockScroll } = usePopupsStore()

    // if popup options were provided
    if (typeof popup !== 'function') {

        // Option - Close on Outside Click
        if (popup.closeOnClickOutside) {
            onClickOutside(popup.closeOnClickOutside, () => (isOpen.value = false), {
                ignore: toValue(popup.ignoreElements),  // TODO: temporary -> remove when https://github.com/vueuse/vueuse/pull/4211 is merged
            })
        }

        // Option - Close on Route Change
        if (popup.autoCloseOn === 'route-change') {
            const route = useRoute()
            watch(() => route.fullPath, () => (isOpen.value = false))
        }

    }

    /**
     * Manage handling of the popup opening and closing.
     * This makes sure that the right callbacks are called and the popup
     * is registered as an open popup when opened and unregistered when closed.
     */
    watch(isOpen, (isOpen) => {
        if (isOpen) {
            // add another open popup to the currently open popups
            saveOpenPopup(typeof popup === 'function'
                ? popup
                : {
                    closeCallback: popup.closeCallback,
                    lockScroll: popup.lockScroll,
                }
            )

            // call the onOpen callback, if provided
            callbacks?.onOpen?.()
        } else {
            // cleanup (make sure we don't leave the popup in the open popups array if we closed it using the v-model)
            // doesn't do anything if the modal is already not present in the open popups array
            const wasPopupPresent = cleanupOpenPopup(typeof popup === 'function' ? popup : popup.closeCallback)
            if (!wasPopupPresent) return

            // call the onClose callback, if provided
            callbacks?.onClose?.()

            // try to auto unlock the scroll if the functionality wasn't disabled
            if (typeof popup === 'function' || ((popup.lockScroll || popup.lockScroll === undefined) && popup.autoUnlockScroll !== false)) {
                tryToUnlockScroll()
            }
        }
    }, { immediate: true })

    onUnmounted(() => {
        // cleanup (make sure we don't leave the popup in the open popups array
        // if the component is no longer mounted in the DOM -> can happen during route change, for example)
        cleanupOpenPopup(typeof popup === 'function' ? popup : popup.closeCallback)

        // update the scroll locked variable to unlock the scroll if needed
        tryToUnlockScroll()
    })
}

interface OpenCloseCallbacks {
    onOpen?: () => void
    onClose?: () => void
}
